
import { Vue, Options } from 'vue-class-component'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faChevronRight,
  faChevronDown,
  faTimesCircle,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'

@Options({
  components: {
    FontAwesomeIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: Array,
      required: true
    }
  }
})
export default class Component extends Vue {
  isExpanded = true
  expandableIcon = faChevronDown
  serverUpIcon = faCheckCircle
  serverDownIcon = faTimesCircle

  toggleExpansion() {
    this.isExpanded = !this.isExpanded
    this.expandableIcon = this.isExpanded ? faChevronDown : faChevronRight
  }
}
