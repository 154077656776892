
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
import VpsContainer from '../components/VpsContainer.vue'
import CustomComponent from '../components/CustomComponent.vue'
import {
  faTimesCircle,
  faCheckCircle,
  faCog
} from '@fortawesome/free-solid-svg-icons'

interface Result {
  server: []
  database: []
}

@Options({
  components: {
    VpsContainer,
    CustomComponent
  }
})
export default class ServiceStatusPage extends Vue {
  serverUpIcon = faCheckCircle
  serverDownIcon = faTimesCircle
  maintenanceIcon = faCog
  serverData: any = []
  mainVPSData: any = []
  subVPSData: any = []
  databaseData: any = []
  expanded = false

  created() {
    this.getServiceStatus()
  }

  toggleExpand() {
    this.expanded = !this.expanded
  }

  async getServiceStatus() {
    await this.$store.dispatch('setLoading', true, { root: true })

    let res: Result = {
      server: [],
      database: []
    }

    res = await axios.get('status')

    if (res) {
      // console.log(`status res: ${JSON.stringify(res)}`)

      this.serverData = res.server

      this.mainVPSData = res.server.find(
        (server: any) => server.title === 'Main VPS'
      )
      this.subVPSData = res.server.find(
        (server: any) => server.title === 'Sub VPS'
      )

      this.databaseData = res.database.find(
        (database: any) => database.title === 'MongoDB'
      )

      // console.log(`main server status: ${this.mainVPSData.status}`)
      // console.log(`sub server status: ${this.subVPSData.status}`)
      // console.log(`database status: ${this.databaseData.status}`)
    }

    await this.$store.dispatch('setLoading', false, { root: true })
  }
}
