<!-- VPSContainer.vue -->
<template>
  <div class="vps-container" :class="{ expanded: expanded }">
    <div>{{ title }}</div>
    <div v-if="expandable" @click="toggleExpand">
      <slot name="status" :toggleExpand="toggleExpand"></slot>
    </div>
    <div v-if="expandable" @click="toggleExpand">
      <slot name="icon" :toggleExpand="toggleExpand"></slot>
    </div>
  </div>
</template>

<script>
import { Vue, Options } from 'vue-class-component'

@Options({
  props: {
    title: '',
    expandable: true
  }
})
export default class VpsContainer extends Vue {
  expanded = false

  toggleExpand() {
    console.log(`${this.expanded}`)
    this.expanded = !this.expanded
  }
}
</script>

<style scoped>
/* Add your custom styles here */
.vps-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.expand-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green; /* Change this to your desired color */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expanded {
  /* Add styles for the expanded state */
}
</style>
